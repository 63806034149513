// import store from "@/state/store";
// import { $axios } from "../helpers/__strdg/ajax/strdg-axios";
// import { baseUrlApi } from "../__strdg/config";


const reminderManagementRoutes = [
	{
		path: "/reminders",
		name: "reminders-list",
		component: () => import("@/views/pages/__strdg/reminder-management/index"),
		meta: {
			title: "Reminders list",
			authRequired: true
		},
	},
	// {
	//   path: "/product-detail/:id",
	//   name: "product-details",
	//   component: () => import("@/views/pages/__strdg/product-management/details"),
	//   meta: {
	// 	title: "Product details",
	// 	authRequired: true
	//   },
	// },
	// {
	//   path: "/product-category",
	//   name: "product-category",
	//   component: () => import("@/views/pages/__strdg/product-management/category"),
	//   meta: {
	// 	title: "Product category",
	// 	authRequired: true
	//   },
	// },
	// // {
	// //   path: "/product-services-list",
	// //   name: "product-services",
	// //   component: () => import("@/views/pages/__strdg/product-management/services"),
	// //   meta: {
	// //     title: "Product services",
	// //     authRequired: true
	// //   },
	// // },
	// {
	//   path: "/product-services-configuration/:id/pax/:pax",
	//   // path: "/product-services-configuration",
	//   name: "product-details-services",
	//   component: () => import("@/views/pages/__strdg/product-management/service-configuration"),
	//   meta: {
	// 	title: "Product services Configuration",
	// 	authRequired: true
	//   },
	// },
]
  
  
export default reminderManagementRoutes