<template>
 <router-view></router-view>

</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<!--
WPJDO UoD
<style lang="scss">
  @import '@/assets/__strdg/style/main.scss';
</style>
-->
