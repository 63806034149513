// import store from "@/state/store";
// import { $axios } from "../helpers/__strdg/ajax/strdg-axios";
// import { baseUrlApi } from "../__strdg/config";


const itineraryManagementRoutes = [
	{
		path: "/itineraries",
		name: "itineraries-list",
		component: () => import("@/views/pages/__strdg/itinerary-management/index"),
		meta: {
			title: "Itineraries list",
			authRequired: true
		},
	},
	{
		path: "/itinerary-detail/:id",
		name: "itinerary-details",
		component: () => import("@/views/pages/__strdg/itinerary-management/details"),
		meta: {
			title: "Itinerary details",
			authRequired: true
		},
	},
	{
		path: "/itinerary-pax-assignament/:id",
		name: "itinerary-details-pax",
		component: () => import("@/views/pages/__strdg/itinerary-management/pax-assignament"),
		meta: {
			title: "Client assignament",
			authRequired: true
		},
	},
	{
		path: "/itinerary-agents-assignament/:id",
		name: "itinerary-details-agents",
		component: () => import("@/views/pages/__strdg/itinerary-management/agents-assignament"),
		meta: {
			title: "Agents assignament",
			authRequired: true
		},
	},
	{
		path: "/itinerary-quotation-product-simulation/:id",
		name: "itinerary-quotation-product-simulation",
		component: () => import("@/views/pages/__strdg/itinerary-management/quotation-product-simulation"),
		meta: {
			title: "Catalogue service details configuration",
			authRequired: true
		},
	},
	{
		path: "/itinerary-quotation-product-details-configuration/:id/quotation/:quotationId/pax/:pax",
		name: "itinerary-quotation-product-details",
		component: () => import("@/views/pages/__strdg/itinerary-management/quotation-product-details-configuration"),
		meta: {
			title: "Catalogue service details configuration",
			authRequired: true
		},
	},
	{
		path: "/itinerary-payment-invite-payment-page/:id",
		name: "itinerary-details-payment-invite-payment-page",
		component: () => import("@/views/pages/__strdg/itinerary-management/payment-invite-payment-page"),
		meta: {
			title: "Payment invite payment page",
			authRequired: true
		},
	},

]


export default itineraryManagementRoutes