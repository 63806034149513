// import store from "@/state/store";
// import { $axios } from "../helpers/__strdg/ajax/strdg-axios";
// import { baseUrlApi } from "../__strdg/config";


const serviceManagementRoutes = [
  {
    path: "/supplier-services",
    name: "supplier-services-list",
    component: () => import("@/views/pages/__strdg/supplier-service-management/index"),
    meta: {
      title: "Supplier services list",
      authRequired: true
    },
  },
  {
    path: "/supplier-service/details/:id",
    name: "supplier-service-details",
    component: () => import("@/views/pages/__strdg/supplier-service-management/details"),
    meta: {
      title: "Supplier service details",
      authRequired: true
    },
  },
]


export default serviceManagementRoutes