
const deleteCookies = () => {
	let cookies = document.cookie.split(";");

	for(let cookie of cookies){

		let equalPosition = cookie.indexOf("=");

		let name =
			equalPosition > -1 ?
				cookie.substring(0, equalPosition) : cookie;

		// let now = new Date()
		// let validityDays = -1
		// let expirationDate =
		// 	date.setTime(now.getTime() - (validityDays*24*60*60*1000));

		document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=.degg.tech;path=/`;
	}
}

const getCookieByName = cookieName => {
	let cookies = document.cookie.split(";");

	for(let cookie of cookies){

		let equalPosition = cookie.indexOf("=");

		let name =
			equalPosition > -1 ?
				cookie.substring(0, equalPosition) : cookie;
		let value =
			equalPosition > -1 ?
				cookie.substring(equalPosition + 1) : cookie;

		if(name === cookieName){
			return value
		}
	}

	return ''
}


export {
	getCookieByName,
	deleteCookies,
}