// import store from "@/state/store";
// import { $axios } from "../helpers/__strdg/ajax/strdg-axios";
// import { baseUrlApi } from "../__strdg/config";


const merchantManagementRoutes = [
	{
		path: "/merchants",
		name: "merchants-list",
		component: () => import("@/views/pages/__strdg/merchant-management/index"),
		meta: {
		title: "Merchants list",
		authRequired: true
		},
	},
	{
		path: "/merchant-detail/:id",
		name: "merchant-details",
		component: () => import("@/views/pages/__strdg/merchant-management/details"),
		meta: {
		title: "Merchant details",
		authRequired: true
		},
	},
	// {
	//   path: "/merchant-permissions-configuration/:id",
	//   // path: "/product-services-configuration",
	//   name: "merchant-details-permissions",
	//   component: () => import("@/views/pages/__strdg/merchant-management/permission-configuration"),
	//   meta: {
	// 	title: "Merchant permissions configuration",
	// 	authRequired: true
	//   },
	// },
	]
	
	
	export default merchantManagementRoutes