// import store from "@/state/store";
// import { $axios } from "../helpers/__strdg/ajax/strdg-axios";
// import { baseUrlApi } from "../__strdg/config";


const userManagementRoutes = [
  {
    path: "/users",
    name: "users-list",
    component: () => import("@/views/pages/__strdg/user-management/index"),
    meta: {
      title: "Users list",
      authRequired: true
    },
  },
  {
    path: "/user-detail/:id",
    name: "user-details",
    component: () => import("@/views/pages/__strdg/user-management/details"),
    meta: {
      title: "User details",
      authRequired: true
    },
  },
  // {
  //   path: "/user-permissions-configuration/:id",
  //   // path: "/product-services-configuration",
  //   name: "user-details-permissions",
  //   component: () => import("@/views/pages/__strdg/user-management/permission-configuration"),
  //   meta: {
  //     title: "User permissions configuration",
  //     authRequired: true
  //   },
  // },
]


export default userManagementRoutes