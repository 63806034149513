// import store from "@/state/store";
// import { $axios } from "../helpers/__strdg/ajax/strdg-axios";
// import { baseUrlApi } from "../__strdg/config";


const agentManagementRoutes = [
	{
		path: "/agencies",
		name: "agencies-list",
		component: () => import("@/views/pages/__strdg/agency-management/index"),
		meta: {
			title: "Agencies list",
			authRequired: true,
		},
	},
	{
		path: "/agency-detail/:id",
		name: "agency-details",
		component: () => import("@/views/pages/__strdg/agency-management/details"),
		meta: {
			title: "Agency details",
			authRequired: true,
		},
	},
	{
		path: "/agency-agents-assignament/:id",
		name: "agency-details-agents",
		component: () => import("@/views/pages/__strdg/agency-management/agent-assignament"),
		meta: {
			title: "Agents assignament",
			authRequired: true,
		},
	},
]


export default agentManagementRoutes