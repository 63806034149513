// import store from "@/state/store";
// import { $axios } from "../helpers/__strdg/ajax/strdg-axios";
// import { baseUrlApi } from "../__strdg/config";


const productCategoryManagementRoutes = [
  {
    path: "/product-category",
    name: "product-category-list",
    component: () => import("@/views/pages/__strdg/product-category-management/index"),
    meta: {
    title: "Product category",
    authRequired: true
    },
  },
  {
    path: "/product-category-detail/:id",
    name: "product-category-details",
    component: () => import("@/views/pages/__strdg/product-category-management/details"),
    meta: {
    title: "Product category details",
    authRequired: true
    },
  },
]
  
  
  export default productCategoryManagementRoutes