// import store from "@/state/store";
// import { $axios } from "../helpers/__strdg/ajax/strdg-axios";
// import { baseUrlApi } from "../__strdg/config";


const agentManagementRoutes = [
	{
		path: "/agents",
		name: "agents-list",
		component: () => import("@/views/pages/__strdg/agent-management/index"),
		meta: {
			title: "Agents list",
			authRequired: true
		},
	},
	{
		path: "/agent-detail/:id",
		name: "agent-details",
		component: () => import("@/views/pages/__strdg/agent-management/details"),
		meta: {
			title: "Agent details",
			authRequired: true
		},
	},
]


export default agentManagementRoutes