// import store from "@/state/store";
// import { $axios } from "../helpers/__strdg/ajax/strdg-axios";
// import { baseUrlApi } from "../__strdg/config";


const servicesCatalogueManagementRoutes = [
  {
    path: "/services-catalogue",
    name: "services-catalogue-list",
    component: () => import("@/views/pages/__strdg/catalogue-service-management/index"),
    meta: {
      title: "Services catalogue list",
      authRequired: true
    },
  },
  {
    path: "/catalogue-service/details/:id",
    name: "catalogue-service-details",
    component: () => import("@/views/pages/__strdg/catalogue-service-management/details"),
    meta: {
      title: "Catalogue service details",
      authRequired: true
    },
  },
]


export default servicesCatalogueManagementRoutes