// import store from "@/state/store";
// import { $axios } from "../helpers/__strdg/ajax/strdg-axios";
// import { baseUrlApi } from "../__strdg/config";


const costListManagementRoutes = [
  {
    path: "/costslists",
    name: "costs-list-list",
    component: () => import("@/views/pages/__strdg/cost-list-management/index"),
    meta: {
      title: "Costs list",
      authRequired: true
    },
  },
  // {
  //   path: "/costs-list-detail/:supplierId/costslists",
  //   name: "costs-list-details",
  //   component: () => import("@/views/pages/__strdg/cost-list-management/details"),
  //   meta: {
  //     title: "Costs list details",
  //     authRequired: true
  //   },
  // },
]


export default costListManagementRoutes