const suppliersManagementRoutes = [
  {
    path: "/suppliers",
    name: "suppliers-list",
    component: () => import("@/views/pages/__strdg/supplier-management/index"),
    meta: {
      title: "Suppliers list",
      authRequired: true
    },
  },
  {
    path: "/supplier-detail/:id",
    name: "supplier-details",
    component: () => import("@/views/pages/__strdg/supplier-management/details"),
    meta: {
      title: "Supplier details",
      authRequired: true
    },
  },
  // {
  //   path: "/supplier-services",
  //   name: "supplier-services",
  //   component: () => import("@/views/pages/__strdg/supplier-management/services"),
  //   meta: {
  //     title: "Supplier services",
  //     authRequired: true
  //   },
  // },
]


export default suppliersManagementRoutes