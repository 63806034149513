import axios from 'axios'
import __router from '@/router/index.js';

import { useToast } from "vue-toastification";


const $axios = axios.create({
  withCredentials: true,
  withXSRFToken: true,
});

$axios.interceptors.request.use(
  async (config) => {
      config.headers = {
        ...config.headers,
      };
    return config;
  },
  (error) => Promise.reject(error)
);
$axios.interceptors.response.use(
  response => {
    return response;
  }, error => {

    const toastNstnc = useToast()


    // WPPRef Manage http status codes
    //
    if(error.response.status === 401){
      __router.push({ name: 'login'})
    } else if(error.response.status === 419){
      __router.push({ name: 'login'})
    } else if(error.response.status === 400){
      if(error.response.data.error){
        toastNstnc.error(error.response.data.error)
      }
    } else if(error.response.status === 422){
      if(error.response.data.error){
        toastNstnc.error(error.response.data.error)
      }
    } else if(error.response.status === 500){
      toastNstnc.error('Internal server error')
    }
    // if (error.response.data.status === 403 && error.response.data.error.reason === 'USER_BANNED') {
    // }



    let errorsObj = error?.response?.data?.errors
    if(errorsObj){


      // WPPRef Ver 1
      // for(let [key, errorRowInfo] of Object.entries(errorsObj)){
      //
      //   for(let errorString of errorRowInfo){
      //     toastNstnc.error(errorString)
      //   }
      // }
      //
      //
      // WPPRef Ver 2
      let maxErrorsToBeShown = 1;
      let counter = 0;

      // for(let [key, errorRowInfo] of Object.entries(errorsObj)){
      for(let errorRowInfo of Object.entries(errorsObj)){

        if(counter < maxErrorsToBeShown){
            for(let errorString of errorRowInfo[1]){
            toastNstnc.error(errorString)
          }
        }


        counter++

      }


    }



    return Promise.reject(error);
  }
);



const $axiosFormData = axios.create({
  withCredentials: true,
  withXSRFToken: true,
});

$axiosFormData.interceptors.request.use(
  async (config) => {
      config.headers = {
        ...config.headers,
        'Content-Type': 'multipart/form-data',
      };
    return config;
  },
  (error) => Promise.reject(error)
);
$axiosFormData.interceptors.response.use(
  response => {
    return response;
  }, error => {

    const toastNstnc = useToast()


    // WPPRef Manage http status codes
    //
    if(error.response.status === 401){
      __router.push({ name: 'login'})
    } else if(error.response.status === 419){
      __router.push({ name: 'login'})
    } else if(error.response.status === 400){
      if(error.response.data.error){
        toastNstnc.error(error.response.data.error)
      }
    } else if(error.response.status === 500){
      toastNstnc.error('Internal server error')
    }
    // if (error.response.data.status === 403 && error.response.data.error.reason === 'USER_BANNED') {
    // }



    let errorsObj = error?.response?.data?.errors
    if(errorsObj){


      // WPPRef Ver 1
      // for(let [key, errorRowInfo] of Object.entries(errorsObj)){
      //
      //   for(let errorString of errorRowInfo){
      //     toastNstnc.error(errorString)
      //   }
      // }
      //
      //
      // WPPRef Ver 2
      let maxErrorsToBeShown = 1;
      let counter = 0;

      // for(let [key, errorRowInfo] of Object.entries(errorsObj)){
      for(let errorRowInfo of Object.entries(errorsObj)){

        if(counter < maxErrorsToBeShown){
            for(let errorString of errorRowInfo[1]){
            toastNstnc.error(errorString)
          }
        }


        counter++

      }


    }



    return Promise.reject(error);
  }
);



const $axiosBlob = axios.create({
  withCredentials: true,
  withXSRFToken: true,
  responseType: 'blob'
});

$axiosBlob.interceptors.request.use(
  async (config) => {
      config.headers = {
        ...config.headers,
      };
    return config;
  },
  (error) => Promise.reject(error)
);
$axiosBlob.interceptors.response.use(
  response => {
    return response;
  }, error => {

    const toastNstnc = useToast()


    // WPPRef Manage http status codes
    //
    if(error.response.status === 401){
      __router.push({ name: 'login'})
    } else if(error.response.status === 419){
      __router.push({ name: 'login'})
    } else if(error.response.status === 400){
      if(error.response.data.error){
        toastNstnc.error(error.response.data.error)
      }
    }
    // if (error.response.data.status === 403 && error.response.data.error.reason === 'USER_BANNED') {
    // }



    let errorsObj = error?.response?.data?.errors
    if(errorsObj){


      // WPPRef Ver 1
      // for(let [key, errorRowInfo] of Object.entries(errorsObj)){
      //
      //   for(let errorString of errorRowInfo){
      //     toastNstnc.error(errorString)
      //   }
      // }
      //
      //
      // WPPRef Ver 2
      let maxErrorsToBeShown = 1;
      let counter = 0;

      // for(let [key, errorRowInfo] of Object.entries(errorsObj)){
      for(let errorRowInfo of Object.entries(errorsObj)){

        if(counter < maxErrorsToBeShown){
            for(let errorString of errorRowInfo[1]){
            toastNstnc.error(errorString)
          }
        }


        counter++

      }


    }



    return Promise.reject(error);
  }
);


export default $axios;
export  { $axios , $axiosFormData, $axiosBlob, axios };