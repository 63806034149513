const authRoutes = [
	// WPPRef Keep
	{
		path: "/recover-password/:token",
		name: "recover-password",
		component: () => import("@/views/pages/__strdg/auth/insert-new-password.vue"),
		meta: {
			title: "Insert new Password",
		},
	},
]


export default authRoutes