// import store from "@/state/store";
// import { $axios } from "../helpers/__strdg/ajax/strdg-axios";
// import { baseUrlApi } from "../__strdg/config";


const productManagementRoutes = [
  {
    path: "/products",
    name: "products-list",
    component: () => import("@/views/pages/__strdg/product-management/index"),
    meta: {
      title: "Products list",
      authRequired: true
    },
  },
  {
    path: "/product-detail/:id",
    name: "product-details",
    component: () => import("@/views/pages/__strdg/product-management/details"),
    meta: {
      title: "Product details",
      authRequired: true
    },
  },
  // {
  //   path: "/product-category",
  //   name: "product-category",
  //   component: () => import("@/views/pages/__strdg/product-management/category"),
  //   meta: {
  //     title: "Product category",
  //     authRequired: true
  //   },
  // },
  // {
  //   path: "/product-services-list",
  //   name: "product-services",
  //   component: () => import("@/views/pages/__strdg/product-management/services"),
  //   meta: {
  //     title: "Product services",
  //     authRequired: true
  //   },
  // },
  {
    path: "/product-services-configuration/:id/pax/:pax",
    // path: "/product-services-configuration",
    name: "product-details-services",
    component: () => import("@/views/pages/__strdg/product-management/service-configuration"),
    meta: {
      title: "Product services configuration",
      authRequired: true
    },
  },
]


export default productManagementRoutes