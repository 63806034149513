// import store from "@/state/store";
// import { $axios } from "../helpers/__strdg/ajax/strdg-axios";
// import { baseUrlApi } from "../__strdg/config";


const clientManagementRoutes = [
	{
	path: "/clients",
	name: "clients-list",
	component: () => import("@/views/pages/__strdg/client-management/index"),
	meta: {
		title: "Clients list",
		authRequired: true
	},
	},
	{
	path: "/client-detail/:id",
	name: "client-details",
	component: () => import("@/views/pages/__strdg/client-management/details"),
	meta: {
		title: "Client details",
		authRequired: true
	},
	},
]


export default clientManagementRoutes